<template>
    <v-container>
        <v-btn
            block
            @click="addAlert"
        >
            Add Alert
        </v-btn>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        alertIndex: 0
    }),
    methods: {
        addAlert () {
            this.alertIndex++
            this.$store.commit('alert/add', {
                text: `Alert ${this.alertIndex}`,
                color: 'success'
            })
        }
    }
}
</script>